@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/AvenirNextLTPro-Bold.469f6fd8.woff);
  font-style: bold;
  font-weight: 600;
}

body {
  overflow-x: hidden;
}

h1 {
  font-family: Avenir;
  font-size: 20px;
  color: #f0f0f0;
  letter-spacing: 1.5rem;
}

h2 {
  font-family: Avenir;
  font-size: 60px;
  color: #f0f0f0;
}

h3 {
  font-family: Roboto;
  font-size: 45px;
  color: #f0f0f0;
}

p {
  font-family: Roboto;
  font-size: 13px;
  color: #f0f0f0;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
}

a {
  text-decoration: none;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/AvenirNextLTPro-Bold.469f6fd8.woff);
  font-style: bold;
  font-weight: 600;
}

.navbar {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
  float: left;
  width: 100vw;
}

.navbar-container {
  display: flex;
  padding: 50px;
  height: 100px;
}

.navbar-logo img {
  width: 100px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100%;
  justify-content: end;
}

.nav-links {
  font-family: Avenir;
  color: #f0f0f0;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  letter-spacing: 0rem;
  transition: all 0.2s ease-out;
}

.nav-links:hover {
  color: #f92f2f;
  letter-spacing: 0.4rem;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #f0f0f0;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar-container {
    display: flex;
    padding: 10px;
    height: 100px;
    background: #000;
    -webkit-tap-highlight-color: transparent;
  }

  .nav-menu {
    background: #000;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: -102vh;
    left: 0px;
    opacity: 1;
    padding: 0px;
    z-index: -999;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    top: 100px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: -999;
  }

  .nav-links {
    text-align: center;
    padding: 2rem 0;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    /*background-color: #f92f2f;
    color: #111111;*/
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;

    right: 20px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #f0f0f0;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #f0f0f0;
    padding: 14px 20px;
    border: 1px solid #f0f0f0;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}

.cards {
  text-align: center;
  width: 100vw;
  background: #111111;
  position: -webkit-sticky;
  position: sticky;
  padding: 80px 0;
}

.img-mask {
  width: 50vw;
  height: 400px;
  overflow: hidden;
  margin: 20px auto;
  display: inline-block;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.6);
  transition: box-shadow 0.1s, transform 0.1s;
}

.cards-item-info {
  display: inline-block;
  position: absolute;
  margin-left: 30px;
  text-align: left;
}
.cards-itens {
  padding: 0;
  list-style-type: none;
}

.cards-item {
  margin-top: 150px;
  transition: box-shadow 0.1s, transform 0.1s;
}

.img-mask:hover {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 1);
}

.cards-item-number {
  margin: 10px auto 0px auto;
}

.cards-item-img {
  margin: -50% -50%;
}

.cards-item-title {
  margin: 0px;
}

.cards-container {
  padding: 0;
}

hr {
  width: 200px;
  border: 0;
  border-top: 1px solid #808080;
  margin-left: 0;
}

@font-face {
  font-family: "Avenir";
  src: url(/static/media/AvenirNextLTPro-Bold.469f6fd8.woff);
  font-style: bold;
  font-weight: 600;
}

.hero-container {
  z-index: 1;
  position: relative;
}

.hero-bg {
  background: url(/static/media/bg.064b6262.jpg) center center/cover no-repeat;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.hero-bg-mask {
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: hidden;
}

.hero-img-container {
  text-align: center;
  height: 100vh;
  position: relative;
}

.hero-img {
  position: absolute;
  left: 45vw;
  bottom: 0;
}

.luci-first-name {
  color: #f0f0f0;
  font-family: Avenir;
  font-size: 2rem;
  position: absolute;
  width: 100vw;
  align-items: center;
  display: flex;
  z-index: 888;
  height: 100vh;
  transform: translate(0, -40px);
}
.first-name {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.luci-last-name {
  color: #f0f0f0;
  font-family: Avenir;
  font-size: 2rem;
  position: absolute;
  width: 100vw;
  align-items: center;
  display: flex;
  z-index: 888;
  height: 100vh;
  transform: translate(0, 40px);
}
.last-name {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.name-hifen {
  width: 30vw;
  height: 4px;
  background: #f0f0f0;
}

.about-container {
  text-align: center;
  width: 100vw;
  background: #0d0d0d;
  position: -webkit-sticky;
  position: sticky;
  padding: 40px 0;
  z-index: 1;
}

.text-container {
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  width: 52vw;
  margin-top: 40px;
}

.button-more {
  width: 80px;
  height: 80px;
  border: 1px solid #f0f0f0;
  border-radius: 100%;
  margin: 40px auto 0 auto;
  text-align: center;
}

.button-more p {
  mix-blend-mode: difference;
  line-height: 51px;
}

