.about-container {
  text-align: center;
  width: 100vw;
  background: #0d0d0d;
  position: sticky;
  padding: 40px 0;
  z-index: 1;
}

.text-container {
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  width: 52vw;
  margin-top: 40px;
}
