.cards {
  text-align: center;
  width: 100vw;
  background: #111111;
  position: sticky;
  padding: 80px 0;
}

.img-mask {
  width: 50vw;
  height: 400px;
  overflow: hidden;
  margin: 20px auto;
  display: inline-block;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.6);
  transition: box-shadow 0.1s, transform 0.1s;
}

.cards-item-info {
  display: inline-block;
  position: absolute;
  margin-left: 30px;
  text-align: left;
}
.cards-itens {
  padding: 0;
  list-style-type: none;
}

.cards-item {
  margin-top: 150px;
  transition: box-shadow 0.1s, transform 0.1s;
}

.img-mask:hover {
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 1);
}

.cards-item-number {
  margin: 10px auto 0px auto;
}

.cards-item-img {
  margin: -50% -50%;
}

.cards-item-title {
  margin: 0px;
}

.cards-container {
  padding: 0;
}

hr {
  width: 200px;
  border: 0;
  border-top: 1px solid #808080;
  margin-left: 0;
}
