@font-face {
  font-family: "Avenir";
  src: url(../fonts/AvenirNextLTPro-Bold.woff);
  font-style: bold;
  font-weight: 600;
}

.hero-container {
  z-index: 1;
  position: relative;
}

.hero-bg {
  background: url("../images/bg.jpg") center center/cover no-repeat;
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.hero-bg-mask {
  height: 100vh;
  width: 100vw;
  position: absolute;
  overflow: hidden;
}

.hero-img-container {
  text-align: center;
  height: 100vh;
  position: relative;
}

.hero-img {
  position: absolute;
  left: 45vw;
  bottom: 0;
}

.luci-first-name {
  color: #f0f0f0;
  font-family: Avenir;
  font-size: 2rem;
  position: absolute;
  width: 100vw;
  align-items: center;
  display: flex;
  z-index: 888;
  height: 100vh;
  transform: translate(0, -40px);
}
.first-name {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.luci-last-name {
  color: #f0f0f0;
  font-family: Avenir;
  font-size: 2rem;
  position: absolute;
  width: 100vw;
  align-items: center;
  display: flex;
  z-index: 888;
  height: 100vh;
  transform: translate(0, 40px);
}
.last-name {
  width: 100vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.name-hifen {
  width: 30vw;
  height: 4px;
  background: #f0f0f0;
}
