@font-face {
  font-family: "Avenir";
  src: url(../fonts/AvenirNextLTPro-Bold.woff);
  font-style: bold;
  font-weight: 600;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 999;
  float: left;
  width: 100vw;
}

.navbar-container {
  display: flex;
  padding: 50px;
  height: 100px;
}

.navbar-logo img {
  width: 100px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 100%;
  justify-content: end;
}

.nav-links {
  font-family: Avenir;
  color: #f0f0f0;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  letter-spacing: 0rem;
  transition: all 0.2s ease-out;
}

.nav-links:hover {
  color: #f92f2f;
  letter-spacing: 0.4rem;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #f0f0f0;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar-container {
    display: flex;
    padding: 10px;
    height: 100px;
    background: #000;
    -webkit-tap-highlight-color: transparent;
  }

  .nav-menu {
    background: #000;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: -102vh;
    left: 0px;
    opacity: 1;
    padding: 0px;
    z-index: -999;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    top: 100px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: -999;
  }

  .nav-links {
    text-align: center;
    padding: 2rem 0;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    /*background-color: #f92f2f;
    color: #111111;*/
    border-radius: 0;
  }

  .menu-icon {
    display: block;
    position: absolute;

    right: 20px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #f0f0f0;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #f0f0f0;
    padding: 14px 20px;
    border: 1px solid #f0f0f0;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
