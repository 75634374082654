.button-more {
  width: 80px;
  height: 80px;
  border: 1px solid #f0f0f0;
  border-radius: 100%;
  margin: 40px auto 0 auto;
  text-align: center;
}

.button-more p {
  mix-blend-mode: difference;
  line-height: 51px;
}
