@font-face {
  font-family: "Avenir";
  src: url(./fonts/AvenirNextLTPro-Bold.woff);
  font-style: bold;
  font-weight: 600;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100&display=swap");

body {
  overflow-x: hidden;
}

h1 {
  font-family: Avenir;
  font-size: 20px;
  color: #f0f0f0;
  letter-spacing: 1.5rem;
}

h2 {
  font-family: Avenir;
  font-size: 60px;
  color: #f0f0f0;
}

h3 {
  font-family: Roboto;
  font-size: 45px;
  color: #f0f0f0;
}

p {
  font-family: Roboto;
  font-size: 13px;
  color: #f0f0f0;
  line-height: 1.5rem;
  letter-spacing: 0.1rem;
}

a {
  text-decoration: none;
}
